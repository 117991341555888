// App.jsx
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Menu from './Menu'; // Importez le composant Menu
import './App.css';
import CarouselComponent from './CarouselComponent';
import ServiceComponent from './ServiceComponent';
import PartenaireComponent from './PartenaireComponent';
import FooterComponent from './FooterComponent';
import Realisation from './Realisation';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ContactBar from './ContactBar';

function Home() {
  return (
    <>

      <CarouselComponent />
      {/*}<ServiceComponent />
      <Realisation />
      <PartenaireComponent />
      {/* Ajoutez le reste de votre contenu 
      <Container>
        <Row>
          <Col>
            {/* Contenu de votre application 
          </Col>
        </Row>
      </Container>*/}
    </>
  );
}

export default Home;
