import React from 'react';
import { Container, Button } from 'react-bootstrap';

function MobileHome() {
  return (
    <Container className="p-4 mobile-home-container">
      <h1 className="text-center mobile-home-title">Lakor Électricité SARL</h1>
      <p className="text-center mobile-home-subtitle">Vos experts en électricité à Genève</p>
      <h2 className="text-center mobile-home-call-to-action">Dépannage urgent, demande de devis express, contactez-nous directement</h2>
      <div className="d-flex flex-column align-items-center mt-4">
        <Button variant="primary" className="animated-button mobile-button" href="tel:+41223482575">
          📞 Appeler
        </Button>
        <Button variant="success" className="animated-button mobile-button" href="mailto:info@lakor-electricite.ch">
          📧 Par Mail
        </Button>
        <Button variant="info" className="animated-button mobile-button" href="https://wa.me/+41779912316">
          💬 WhatsApp
        </Button>
        <Button variant="warning" className="animated-button mobile-button" href="https://www.google.com/maps/place/Rue+de+Genève+3,+1225+Chêne-Bourg,+Suisse/@46.1957519,6.1923379,17z/data=!3m1!4b1!4m5!3m4!1s0x478c6ffd9d9ff9ed:0xad0b527d47d5edc2!8m2!3d46.1957519!4d6.1945266" target="_blank">
          🗺️ Nous trouver
        </Button>
      </div>
      <div className="opening-hours text-center mt-3">Heures d'ouverture: 8h à 17h</div>
    </Container>
  );
}

export default MobileHome;
