// Menu.jsx
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Menu.css';

const Menu = () => {
  const logoSmall = "/logobig.png";
  const logoBig = "/logobig.png";

  return (
    <div className="menu-container">
      <Navbar bg="white" variant="white" expand="md">
        <Navbar.Brand href="/">
          <img
            src={logoBig}
            width="600"
            height="200"
            className="d-none d-md-block" // Affiche le logo pour les écrans moyens et plus grands
            alt="Logo"
          />
          <img
            src={logoSmall}
            width="300"
            height="100"
            className="d-md-none" // Affiche le logo pour les écrans plus petits que moyens
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto navbar-text nav-menu">
            <Nav.Link href="/">Accueil</Nav.Link>
            <Nav.Link href="/entreprise">L’entreprise</Nav.Link>
            <Nav.Link href="/services">Services</Nav.Link>
            <Nav.Link href="/references">Références</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
  <Nav.Link href="/conditions">Conditions générales</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="line-container justify-content-end">
        <div className="line-black"></div>
        <div className="line-gold"></div>
      </div>
    </div>
  );
};

export default Menu;
