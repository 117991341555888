import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faCertificate, faDollarSign, faShieldAlt, faHistory, faHandshake } from '@fortawesome/free-solid-svg-icons';
import './Entreprise.css'; // Assurez-vous d'ajouter les styles responsive ici

const Entreprise = () => {
    return (
        <div className="entreprise-container">
            {/* Carousel */}
            <div className="d-none d-md-block">
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="./img/carousel1.jpg"
                            alt="Vue de l'entreprise"
                        />
                        <Carousel.Caption>
                            <h3>Découvrez notre entreprise</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>

            {/* Présentation de l'entreprise */}
            <Container className="presentation-container my-5">
                <Row>
                    <Col md={12} xs={12}>
                        <h2>Qui sommes-nous ?</h2>
                        <p>
                            L'entreprise Lakor Électricité est le fruit du projet de deux électriciens qui, forts de leurs nombreuses années d'expérience sur le terrain, ont décidé de s'associer pour mettre leur savoir-faire au service des clients et être au plus proche de leurs besoins.
                        </p>
                        <p>
                            Que ce soit pour des dépannages régies ou des villas d'architecte, en neuf ou en rénovation, David et Labinot sauront rapidement répondre à vos demandes.
                        </p>
                        <p>
                            L'entreprise est installée à Chêne-Bourg, dans les locaux partagés avec l'un de nos partenaires, Lucifero Éclairages.
                        </p>
                    </Col>
                </Row>
            </Container>

            {/* Liste des services */}
            <Container className="services-container text-center">
                <Row>
                    <Col md={4} sm={6} className="service-item">
                        <FontAwesomeIcon icon={faTools} size="3x" className="service-icon" />
                        <h3>Installation électrique</h3>
                    </Col>
                    <Col md={4} sm={6} className="service-item">
                        <FontAwesomeIcon icon={faCertificate} size="3x" className="service-icon" />
                        <h3>Certification</h3>
                    </Col>
                    <Col md={4} sm={6} className="service-item">
                        <FontAwesomeIcon icon={faDollarSign} size="3x" className="service-icon" />
                        <h3>Tarifs compétitifs</h3>
                    </Col>
                    <Col md={4} sm={6} className="service-item">
                        <FontAwesomeIcon icon={faShieldAlt} size="3x" className="service-icon" />
                        <h3>Durabilité</h3>
                    </Col>
                    <Col md={4} sm={6} className="service-item">
                        <FontAwesomeIcon icon={faHistory} size="3x" className="service-icon" />
                        <h3>Expérience</h3>
                    </Col>
                    <Col md={4} sm={6} className="service-item">
                        <FontAwesomeIcon icon={faHandshake} size="3x" className="service-icon" />
                        <h3>Suivi client</h3>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Entreprise;
