// CarouselComponent.jsx
import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import './CarouselComponent.css'; // Importez votre fichier de styles

const CarouselComponent = () => {
  return (
    <Carousel defaultActiveIndex={1}>
      {/* Page 1 */}
      <Carousel.Item>
      <video className="d-block w-100 carousel-video" autoPlay loop muted>
          <source src="/videos/presentation.mp4" type="video/mp4" />
          Votre navigateur ne prend pas en charge la balise vidéo.
        </video>
        <Carousel.Caption className="carousel-caption">
          <h3>DES ÉLECTRICIENS À VOTRE SERVICE</h3>
          <div className="button-container">
            <Button className="animated-button" variant="primary">Qui sommes-nous</Button>{' '}
            <Button className="animated-button" variant="secondary">Que faisons-nous</Button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      {/* Page 2 avec une vidéo */}
      <Carousel.Item>
        <video className="d-block w-100 carousel-video" autoPlay loop muted>
          <source src="/videos/presentation.mp4" type="video/mp4" />
          Votre navigateur ne prend pas en charge la balise vidéo.
        </video>
        <Carousel.Caption className="carousel-caption">
          <h3>VOTRE PARTENAIRE DANS VOS PROJETS EN ÉLECTRICITÉ</h3>
          <div className="button-container">
            <Button className="animated-button" variant="primary">Nos références</Button>{' '}
            <Button className="animated-button" variant="secondary">Contactez-nous</Button>
          </div>
          
        </Carousel.Caption>
      </Carousel.Item>

      {/* Page 3 */}
      <Carousel.Item>
      <video className="d-block w-100 carousel-video" autoPlay loop muted>
          <source src="/videos/presentation.mp4" type="video/mp4" />
          Votre navigateur ne prend pas en charge la balise vidéo.
        </video>
        <Carousel.Caption className="carousel-caption">
          <h3>ÉNERGIES RENOUVELABLES - ÉLECTRICITÉ PHOTOVOLTAÏQUE</h3>
          <div className="button-container">
            <Button className="animated-button" variant="primary">Contactez-nous</Button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
