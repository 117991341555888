import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './FooterComponent.css'; // Assurez-vous que ce chemin est correct

const FooterComponent = () => {
  return (
    <Container fluid className="footer-container">
      <Row>
        <Col md={4} sm={12}>
          <div className="address-section">
            <h5>Lakor électricité SARL</h5>
            <p>Rue de Genève 3, 1225 Chêne-Bourg</p>
            <p>Genève, Suisse</p>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <h5>Horaires</h5>
          <p>Lundi – Vendredi : 8h00 – 12h00, 13h30 – 17h00</p>
          <p>Samedi – Dimanche : fermé</p>
        </Col>
        <Col md={4} sm={12}>
          <div className="contact-section">
            <h5>Contact</h5>
            <p>Email: info@lakor-electricite.ch</p>
            <p>Téléphone: 022 348 25 75</p>
            <p>Adresse: Rue de Genève 3, 1225 Chêne-Bourg</p>
          </div>
        </Col>
      </Row>
      <Row className="conditions-generales">
        <Col>
          <a href="/conditions">Voir nos conditions générales</a>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterComponent;
