import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faShieldAlt, faWifi, faClipboardCheck, faProjectDiagram, faSolarPanel } from '@fortawesome/free-solid-svg-icons';
import './Services.css';

const Services = () => {
    return (
        <div className="services-container">
            {/* Carousel */}
            <div className="d-none d-md-block">
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="./img/carousel2.jpg"
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3>Nos Services</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>

            {/* Contenu des services */}
            <Container className="service-content my-5">
                <Row>
                    <Col>
                        <h2>Votre Électricien Expert</h2>
                        <p>
                            Lakor Électricité à Genève propose des solutions électriques avancées et personnalisées. Nous nous spécialisons dans l'installation, les études techniques, la domotique, l'informatique, la téléphonie, la sécurité et la production d'énergie photovoltaïque. Chez Lakor Électricité, nous sommes votre partenaire de confiance pour tous vos besoins électriques.
                        </p>
                    </Col>
                </Row>

                {/* Compétences */}
                <Row className="mt-4">
                    <Col>
                        <h3>Nos Compétences</h3>
                        <ul className="competences-list">
                            <li><a href="#courant-fort"><FontAwesomeIcon icon={faBolt} className="service-icon" /> Installation à courant fort</a></li>
                            <li><a href="#securite"><FontAwesomeIcon icon={faShieldAlt} className="service-icon" /> Contrôle de sécurité </a></li>
                            <li><a href="#courant-faible"><FontAwesomeIcon icon={faWifi} className="service-icon" /> Installation à courant faible</a></li>
                            <li><a href="#expertise"><FontAwesomeIcon icon={faClipboardCheck} className="service-icon" /> Expertises d’installations</a></li>
                            <li><a href="#etude-projet"><FontAwesomeIcon icon={faProjectDiagram} className="service-icon" /> Étude & Projet</a></li>
                            <li><a href="#energies-renouvelables"><FontAwesomeIcon icon={faSolarPanel} className="service-icon" /> Énergies renouvelables</a></li>
                        </ul>
                    </Col>
                </Row>

                {/* Détails des compétences */}
                <Row className="mt-4" id="courant-fort">
                    <Col md={2} className="icon-col">
                        <FontAwesomeIcon icon={faBolt} size="6x" className="service-icon-large" />
                    </Col>
                    <Col md={10}>
                        <h4>Installation à courant fort</h4>
                        <p>
                            Nous réalisons des installations électriques pour tous types de bâtiments, qu'ils soient commerciaux, résidentiels ou industriels. Nos experts sont régulièrement formés pour intégrer les dernières technologies et offrir des solutions durables et efficaces.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-4" id="securite">
                    <Col md={2} className="icon-col">
                        <FontAwesomeIcon icon={faShieldAlt} size="6x" className="service-icon-large" />
                    </Col>
                    <Col md={10}>
                        <h4>Contrôle de sécurité</h4>
                        <p>
                            Nos contrôles de sécurité garantissent que vos installations électriques répondent aux normes en vigueur et fonctionnent en toute sécurité. Nous effectuons des inspections rigoureuses pour détecter et corriger toute anomalie.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-4" id="courant-faible">
                    <Col md={2} className="icon-col">
                        <FontAwesomeIcon icon={faWifi} size="6x" className="service-icon-large" />
                    </Col>
                    <Col md={10}>
                        <h4>Installation à courant faible</h4>
                        <p>
                            Nous installons des systèmes à courant faible pour les réseaux de communication, les systèmes de sécurité et les installations domotiques. Nos solutions assurent une connectivité fiable et une gestion optimale de vos dispositifs électroniques.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-4" id="expertise">
                    <Col md={2} className="icon-col">
                        <FontAwesomeIcon icon={faClipboardCheck} size="6x" className="service-icon-large" />
                    </Col>
                    <Col md={10}>
                        <h4>Expertises d’installations</h4>
                        <p>
                            Nous offrons des services d'expertise pour évaluer l'état et la performance de vos installations électriques. Nos rapports détaillés vous aident à prendre des décisions informées pour la maintenance ou la mise à niveau de vos systèmes électriques.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-4" id="etude-projet">
                    <Col md={2} className="icon-col">
                        <FontAwesomeIcon icon={faProjectDiagram} size="6x" className="service-icon-large" />
                    </Col>
                    <Col md={10}>
                        <h4>Étude & Projet</h4>
                        <p>
                            Nous prenons en charge l'étude et la planification de vos projets électriques, en veillant à ce que chaque détail soit soigneusement examiné. De la conception à l'exécution, nous garantissons une mise en œuvre conforme à vos besoins et attentes.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-4" id="energies-renouvelables">
                    <Col md={2} className="icon-col">
                        <FontAwesomeIcon icon={faSolarPanel} size="6x" className="service-icon-large" />
                    </Col>
                    <Col md={10}>
                        <h4>Énergies renouvelables</h4>
                        <p>
                            Nous proposons des solutions en énergies renouvelables, y compris l'installation de panneaux solaires et d'autres technologies vertes. Notre objectif est de vous aider à réduire votre empreinte carbone et à adopter des pratiques énergétiques durables.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Services;
