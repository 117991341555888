const spacing = 10; // Replace with your desired spacing value

const iframeStyle = {
  border: '0',
  marginRight: `${spacing}em`,
};

const MapEmbed = () => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2761.7390177913294!2d6.192337900000001!3d46.195751900000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c6ffd9d9ff9ed%3A0xad0b527d47d5edc2!2sRue%20de%20Gen%C3%A8ve%203%2C%201225%20Ch%C3%AAne-Bourg!5e0!3m2!1sfr!2sch!4v1718915240001!5m2!1sfr!2sch"
    width="300"
    height="250"
    style={iframeStyle}
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
  ></iframe>
);

export default MapEmbed;