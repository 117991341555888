// ContactBar.jsx
import React, { useEffect, useState } from 'react';
import './ContactBar.css';

const ContactBar = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`contact-bar${isVisible ? ' visible' : ''}`}>
      En cas d'urgence vous pouvez nous contacter au  022 348 25 75
    </div>
  );
};

export default ContactBar;
