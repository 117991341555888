import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Menu from './Menu';
import FooterComponent from './FooterComponent';
import ContactBar from './ContactBar';

import Home from './Home';
import Entreprise from './Entreprise';
import Services from './Services';
import ContactComponent from './ContactComponent';
import ConditionsGenerales from './ConditionsGenerales';
import MobileHome from './MobileHome'; 
import useMediaQuery from './hooks/useMediaQuery';

import Reference from './Reference'; 

function App() {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Router>
      <Menu />
      <Routes>
        <Route path='/' element={isMobile ? <MobileHome /> : <Home />} />
        <Route path="/entreprise" element={<Entreprise />} />
        <Route path="/services" element={<Services />} />
        <Route path="/references" element={<Reference />} />
        <Route path="/contact" element={<ContactComponent />} />
        <Route path="/conditions" element={<ConditionsGenerales />} />
      </Routes>
      <FooterComponent />
      <ContactBar />
    </Router>
  );
}

export default App;
