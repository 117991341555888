import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Reference.css'; // Assurez-vous d'ajouter les styles appropriés

const logos = [
    { src: './img/ref/ana-k.png', alt: 'Ana-K Design', link: 'https://www.ana-k-design.ch/fr/home' },
    { src: './img/ref/latour.png', alt: 'Immotour', link: 'https://www.immotour-ge.ch/' },
    { src: './img/ref/rizzo.png', alt: 'Groupe Rizzo', link: 'https://www.groupe-rizzo.com/' },
    { src: './img/ref/manmoon.png', alt: 'Manmoon', link: 'https://www.manmoon.ch/' },
    // Ajoutez autant de logos que nécessaire
];

const Reference = () => {
    return (
        <div className="reference-container">
            <Container>
                <h2>Ils nous ont fait confiance</h2>
                <Row>
                    {logos.map((logo, index) => (
                        <Col xs={6} md={3} key={index} className="logo-col">
                            <a href={logo.link} target="_blank" rel="noopener noreferrer">
                                <img src={logo.src} alt={logo.alt} className="logo-img" />
                            </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default Reference;
