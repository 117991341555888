// ConditionsGenerales.jsx
import React from 'react';
import './ConditionsGenerales.css';

const ConditionsGenerales = () => {
  return (
    <div className="conditions-container">
      
      <div className="centered-content">
        <button className="pdf-button">Version originale en PDF</button>
      </div>
      <h2>CONDITIONS GÉNÉRALES DU CONTRAT D’ENTREPRISE AIEG</h2>

      <p>Version Juin 2019</p>

      <section>
        <h3>1. Généralités</h3>
        <p>Les bases suivantes sont déterminantes et font parties intégrantes de l’offre :</p>
        <ul>
          <li>L’offre</li>
          <li>Les plans et données techniques fournis par le maître d’ouvrage</li>
          <li>Le devis accepté par le maître d’ouvrage</li>
          <li>Les plans acceptés par le maître d’ouvrage</li>
          <li>Les présentes conditions générales</li>
          <li>Les normes SIA-118</li>
        </ul>
        <p>Toute modification des présentes conditions générales doit être approuvée par les parties et revêtir la forme écrite.</p>
      </section>

      <section>
        <h3>2. Définitions</h3>
        <p>Par maître d’ouvrage, on entend celui qui donne l’ordre. Cette définition englobe les représentants du maître d’ouvrage, tels que l’architecte ou la direction des travaux. En l’absence de motif manifeste, l’entrepreneur n’est pas obligé de vérifier si le maître d’ouvrage est autorisé à procéder à des actes juridiques.</p>
        <p>Par ouvrage, on entend entre autres toute installation électrique et de télécommunication. L’ouvrage est aussi le résultat d’une réparation, d’une transformation ou d’une démolition.</p>
        <p>Par entrepreneur, on entend celui qui s’engage directement envers le maître d’ouvrage à exécuter l’ouvrage à réaliser. Ce dernier est autorisé à s’adjoindre les services de sous-traitants.</p>
      </section>

      <section>
        <h3>3. Offre</h3>
        <p>L’offre s’entend sans engagement. Tout devis reste valable pendant 30 jours à compter de sa date d’émission.</p>
        <p>Les offres restent la propriété de l’entrepreneur et doivent être restituées à sa demande si le contrat n’est pas conclu.</p>
      </section>

      <section>
        <h3>4. Prix</h3>
        <p>Les prix incluent la TVA.</p>
        <p>Les prix n’incluent pas, sauf convention expresse :</p>
        <ul>
          <li>Etude, calculs et dessins que l’entreprise doit réaliser pour établir son offre.</li>
          <li>Frais de polycopies, réimpression, copies de plans et autres reproductions ainsi que ceux résultant de la remise d’échantillons.</li>
          {/* ... (autres éléments de la liste) */}
        </ul>
        <p>La rémunération des prestations précitées fait l’objet d’un accord spécifique.</p>
        <p>Toute modification de plan ou de fabrication par rapport au descriptif de l’offre initiale entraine un nouveau calcul du prix.</p>
        <p>Le prix de la main d’oeuvre est calculé en tenant compte du temps de déplacement entre le siège de l’entreprise et le lieu du chantier aller-retour.</p>
        {/* ... (autres paragraphes de la section) */}
      </section>

      {/* ... (autres sections) */}

      <section>
        <h3>13. For juridique et Droit applicable</h3>
        <p>En cas de litige afférent au présent contrat, le for juridique est Genève et le droit applicable est le droit suisse.</p>
        <p>Toutes modifications des présentes conditions générales devront être approuvées par les parties et revêtir la forme écrite.</p>
      </section>

      <div className="signatures">
        <p>LE MAÎTRE D’OUVRAGE</p>
        <p>L’ENTREPRENEUR</p>
        <p>LE MANDATAIRE</p>
        <p>Genève, le……………………………….</p>
      </div>
    </div>
  );
};

export default ConditionsGenerales;
